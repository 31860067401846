<template>
  <b-card
    no-body
    class="p-3"
  >
    <b-row >
      <b-col
        cols="12"
      >
        <validation-observer ref="formRef">
          <b-form @submit.prevent>
            <b-row>
              <!-- PO Field -->
              <b-col cols="12" md="3">
                <b-form-group
                  :label="$t(`apps.transactions.purchase.goodsRecipient.singular.orderNo`)"
                  label-for="orderNo"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="orderNo"
                    rules="required"
                  >
                    <v-select
                      id="purchase-order-no"
                      class="select-size-sm"
                      v-model="form.orderNo"
                      :options="LOV.orderNo"
                      :reduce="field => field.id"
                      label="code"
                      :state="errors.length > 0 ? false:null"
                      :disabled="actions.isPreview"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Date Field -->
              <b-col cols="12" md="3">
                <b-form-group
                  :label="$t('globalSingular.date')"
                  label-for="date"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="date"
                    rules="required"
                  >
                    <flat-pickr
                      id="date"
                      :config="dateFormat"
                      v-model="form.date"
                      :state="errors.length > 0 ? false:null"
                      :disabled="actions.isPreview"
                      class="form-control form-control-sm"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Order Field -->
              <b-col cols="12" md="3">
                <b-form-group
                  :label="$t('globalSingular.code')"
                  label-for="code"
                >
                  <!-- <validation-provider
                    #default="{ errors }"
                    name="code"
                    rules="required"
                  > -->
                    <b-form-input
                      id="code"
                      size="sm"
                      v-model="form.code"
                      :placeholder="$t('globalSingular.auto')"
                      :disabled="actions.isPreview"
                    />
                    <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                  <!-- </validation-provider> -->
                </b-form-group>
              </b-col>

              <!-- Tags Field -->
              <b-col cols="12" md="3">
                <b-form-group
                  :label="$t('globalSingular.tags')"
                  label-for="tags"
                >
                  <v-select
                    v-model="form.tags"
                    id="tags"
                    label="name"
                    multiple
                    :options="LOV.tags"
                    :reduce="field => field.id"
                    :searchable="!actions.isPreview"
                    :selectable="option => !actions.isPreview"
                  />
                </b-form-group>
              </b-col>
              <!-- Penerima barang -->
                <b-col cols="12" md="3">
                  <b-form-group
                  :label="$t('globalSingular.namePenerimaBarang')"
                  label-for="recipientsName"
                  >
                  <validation-provider
                    #default="{ errors }"
                    name="recipientsName"
                    rules="required"
                  >
                    <b-form-input
                      id="recipientsName"
                      size="sm"
                      v-model="form.recipientsName"
                      :placeholder="!actions.isPreview ? $t('globalSingular.namePenerimaBarang') : null"
                      :disabled="actions.isPreview"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
            </b-row>

            <b-row class="my-1">

              <b-col cols="12" md="3" class="d-flex align-items-center">
                <b-button
                  class="mb-sm-1 mb-md-0"
                  :variant="actions.isPreview ? 'outline-secondary' : 'primary'"
                  @click="getSelectedOrder()"
                >
                  <!-- <feather-icon
                    icon="ArrowLeftIcon"
                  /> -->
                  {{ $t('globalActions.generate') }}
                  <!-- Generate -->
                </b-button>
              </b-col>
            </b-row>

            <b-table
              id="refFormTable"
              ref="refFormTable"
              responsive
              no-provider-paging
              no-provider-filtering
              :fields="tableColumns"
              :items="detailOrder"
              primary-key="id"
              show-empty
              class="position-relative mt-2"
            >
              <!-- Custom Header Column -->
              <template #head(actions)>
                <feather-icon
                  icon="MoreVerticalIcon"
                  class="mx-auto"
                />
              </template>
              <!-- Column: Order No -->
              <template #cell(orderNo)="{ index }">
                <b-form-group>
                    <b-form-input size="sm" v-model="form.details[index].orderNo" disabled />
                    <b-form-input :hidden="true" size="sm" v-model="form.details[index].orderId" disabled />
                    <b-form-input :hidden="true" size="sm" v-model="form.details[index].orderDetailsId" disabled />
                </b-form-group>
              </template>
              <!-- Column: Item -->
              <template #cell(item)="{ index }">
                <b-form-group>
                    <b-form-input size="sm" v-model="form.details[index].item" disabled />
                    <b-form-input :hidden="true" size="sm" v-model="form.details[index].itemId" disabled />
                </b-form-group>
              </template>
              <!-- Column: Order total qty -->
              <template #cell(quantityOrder)="{ index }">
                <b-form-group>
                    <cleave
                      :key="index"
                      v-model.number="form.details[index].quantityOrder"
                      class="form-control text-right form-control-sm"
                      :options="numeric"
                      placeholder="10"
                      disabled
                    />
                </b-form-group>
              </template>
              <!-- Column: Outstanding order qty -->
              <template #cell(quantityOutstanding)="{ index }">
                <b-form-group>
                    <cleave
                      :key="index"
                      v-model.number="form.details[index].quantityOutstanding"
                      class="form-control text-right form-control-sm"
                      :options="numeric"
                      placeholder="10"
                      disabled
                    />
                </b-form-group>
              </template>
              <!-- Column: Order qty -->

              <!-- :rules="`required|${!actions.isPreview ? `max_value:${form.details[index].quantityOutstanding}` : ''}`"
                    :vid="`detail-${index}`" -->
              <template #cell(quantity)="{ index }">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="quantity"
                  >
                    <cleave
                      :key="index"
                      v-model.number="form.details[index].quantity"
                      :state="errors.length > 0 ? false:null"
                      class="form-control text-right form-control-sm"
                      :options="numeric"
                      placeholder="10"
                      :disabled="actions.isPreview"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </template>
            </b-table>

            <b-row>
              <b-col cols="12" md="12">
                <b-form-group
                  :label="$t('globalSingular.notes')"
                  label-for="notes"
                >
                  <b-form-textarea
                    id="notes"
                    size="sm"
                    v-model="form.remark"
                    :placeholder="!actions.isPreview ? $t('globalSingular.remark') : null"
                    :disabled="actions.isPreview"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <div class="mt-3 d-flex flex-sm-column flex-md-row justify-content-between">
              <b-button
                class="mb-sm-1 mb-md-0"
                :variant="actions.isPreview ? 'outline-secondary' : 'outline-primary'"
                :to="{ name: 'apps-transactions-purchase-goods-recipient-list' }"
              >
                <feather-icon
                  icon="ArrowLeftIcon"
                />
                {{ $t('globalActions.backToList') }}
              </b-button>

              <b-button
                v-if="actions.isPreview"
                class=" mb-sm-1 mb-md-0"
                variant="outline-danger"
                @click="handleDelete"
              >
                <feather-icon
                  icon="Trash2Icon"
                />
                {{ $t('globalActions.delete') }}
              </b-button>

              <b-button
                v-if="!actions.isPreview"
                variant="primary"
                type="submit"
                @click="handleSubmit"
              >
                <feather-icon
                  icon="SaveIcon"
                />
                {{ actions.isEditMode ? $t('globalActions.update') : $t('globalActions.create') }}
              </b-button>

              <!-- <b-button
                v-else
                variant="outline-primary"
                @click="changeToEdit"
              >
                <feather-icon
                  icon="EditIcon"
                />
                {{ $t('globalActions.changeToEdit') }}
              </b-button> -->
            </div>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BButton
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import { ref, onMounted, computed } from '@vue/composition-api'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, maxValue } from '@validations'
import { formatCurrency , formatDatepicker} from '@/utils/formatter'
import VuexStore from '@/store'

import useFormResource from '@/comp-functions/useFormResource'
import usePushTags from '@/comp-functions/utils/usePushTags'
import useLocalization from '@/comp-functions/utils/useLocalization'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    Cleave
  },
  setup () {
    const {
      paramsId,
      // routeParams,
      formRef,
      get,
      actions,
      changeToEdit,
      show,
      destroy,
      store
    } = useFormResource({ url: 'transaction/goods-recipient', localeContextPath: 'apps.transactions.purchase.goodsRecipient.singular.goodsRecipient', redirectPathName: 'apps-transactions-purchase-goods-recipient' })

    const company = computed(() => {
      return VuexStore.state.user.currentCompany
    })

    const { $t } = useLocalization()

    const LOV = ref({
      orderNo: [],
      contacts: [],
      accounts: [],
      tags: [],
      outstandingOrders: []
    })

    const getPurchaseOrder = async () => {
      LOV.value.orderNo = await get({ url: 'value/outstanding-goods-recipients'})
    }

    const getAccounts = async () => {
      LOV.value.accounts = await get({ url: 'master/accounts/child'})
    }

    const getContacts = async () => {
      LOV.value.contacts = await get({ url: 'value/contact/supplier'})
    }

    const getTags = async () => {
      LOV.value.tags = await get({ url: 'value/tag'})
    }

    const tableColumns = ref([])

    const form = ref({
      orderNo: '',
      code: '',
      date: formatDatepicker(Date.now()),
      tags: [],
      remark: '',
      recipientsName: '',
      details: [
        {
          // rowNumber: null,
          orderId: null,
          orderDetailsId: null,
          orderNo: null,
          order: null,
          item: null,
          itemId: null,
          quantityOrder: null,
          quantityOutstanding: null,
          quantity: null
        }
      ]
    })

    const getSelectedOrder = async () => {
      let selectedOrder = LOV.value.orderNo.find(field => field.id == form.value.orderNo)
      form.value.details = selectedOrder.details.map((order) => {
        return {
          // rowNumber: index++,
          orderId: order.order_id,
          orderDetailsId: order.id,
          orderNo: selectedOrder.code,
          item: order.product_name,
          itemId: order.product_id,
          quantityOrder: parseInt(order.quantity) || 0,
          quantityOutstanding: parseInt(order.quantity_outstanding) || 0,
          quantity: 0
        }
      })
    }

    const dateFormat = ref({ dateFormat: 'd/m/Y', allowInput: true })

    const numeric = ref({
      numeral: true,
      numeralThousandsGroupStyle: 'thousand',
      numeralPositiveOnly: true
    })

    // will get data to show the preview
    const getDataPreview = async () => {
      const data = await get({ url: `transaction/goods-recipient/${paramsId}`})
      form.value.orderNo = data.purchase_order.code
      form.value.date = data.date
      form.value.tags = data.tags.map(tag => parseInt(tag))
      form.value.remark = data.remark
      form.value.recipientsName = data.recipients_name
      form.value.details = data.details.map(field => {
        return {
          // rowNumber: field.row_number,
          orderId: field.order_id,
          orderDetailsId: field.order_details_id,
          orderNo: data.purchase_order.code,
          item: field.product.name,
          itemId: field.item_id,
          quantityOrder: parseInt(field.quantity_order) || 0,
          quantityOutstanding: parseInt(field.quantity_outstanding) || 0,
          quantity: parseInt(field.quantity) || 0
        }
      })
    }

    const detailOrder = computed(() => form.value.details.filter(field => field.quantityOutstanding > 0))

    onMounted(() => {
      getAccounts()
      getTags()
      getContacts()
      if (paramsId) {
        actions.value.isPreview = true
        getDataPreview()
      }
      getPurchaseOrder()
      tableColumns.value = [
        // { key: 'rowNumber', label: '#', width: '10%', thClass: 'bg-transparent pl-0', tdClass: 'align-top pl-0' },
        { key: 'orderNo', label: $t('apps.transactions.purchase.goodsRecipient.singular.orderNo'), width: '24%', thClass: 'bg-transparent pl-0 width-25-per', tdClass: 'align-top pl-0' },
        { key: 'item', label: $t('apps.transactions.purchase.goodsRecipient.singular.item'), width: '24%', thClass: 'bg-transparent pl-0 width-25-per', tdClass: 'align-top pl-0' },
        { key: 'quantityOrder', label: $t('apps.transactions.purchase.goodsRecipient.singular.totalQuantity'), width: '24%', thClass: 'bg-transparent text-right width-25-per', tdClass: 'align-top text-right'},
        { key: 'quantityOutstanding', label: $t('apps.transactions.purchase.goodsRecipient.singular.outstandingQuantity'), width: '24%', thClass: 'bg-transparent text-right width-25-per', tdClass: 'align-top text-right'},
        { key: 'quantity', label: $t('apps.transactions.purchase.goodsRecipient.singular.quantity'), width: '24%', thClass: 'bg-transparent text-right width-25-per', tdClass: 'align-top text-right'},
        // { key: 'actions', width: '12%', thClass: 'bg-transparent text-right width-10-per  pr-0', tdClass: 'align-top text-right pr-0'}
      ]
    })

    const { pushTagsToAPI } = usePushTags()
    const pushTags = async newTag => {
      const id = await pushTagsToAPI(newTag)
      form.value.tags[form.value.tags.findIndex(val => val === newTag.id)] = id
    }

    return {
      company,
      getSelectedOrder,
      pushTags,
      required,
      maxValue,
      LOV,
      tableColumns,
      detailOrder,
      form,
      dateFormat,
      formatCurrency,
      numeric,
      paramsId,
      formRef,
      get,
      actions,
      changeToEdit,
      show,
      destroy,
      store
    }
  },
  methods: {
    handleDelete () {
      this.destroy(this.$swal)
    },
    handleSubmit () {
      this.store({
        $swal: this.$swal,
        data: this.form
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
